import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

interface QuestSectionFilterProps {
  filter?: string[];
  label: string;
  onChange: (el: any) => void;
}

export const QuestSectionFilter = ({
  filter,
  label,
  onChange,
}: QuestSectionFilterProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`label-${label}`}>{label}</InputLabel>
      <Select
        defaultValue=""
        labelId={`label-${label}`}
        label={label}
        onChange={onChange}
        name={label}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {filter?.map((el: string) => (
          <MenuItem value={el} id={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
