import React from 'react';
import {
  ButtonBackPlaylist,
  NavigationSection,
  NavigationTitle,
} from './components';
import { AddButton } from 'modules/relationships/components';
import { VerticalNavbarProps } from './typings';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailable } from 'modules/home/playlist/selectors';
import { updateAvailableList } from 'modules/home/playlist/actions';
import AddIcon from 'components/icons/AddIcon';
import NavigationButton from './NavigationButton';
import localizedStrings from 'localization/en';

export const VerticalNavPlaylistContent = ({
  title,
  items,
  selectedItem,
  onItemClick,
}: VerticalNavbarProps) => {
  const dispatch = useDispatch();
  const showAvailableItems = useSelector(selectAvailable);

  const handleChangeAvailablePlaylistStatus = (status: boolean) =>
    dispatch(updateAvailableList(status));

  return (
    <NavigationSection>
      <NavigationTitle>
        {!showAvailableItems ? title : localizedStrings.availableMedia}
        {!showAvailableItems && (
          <AddButton
            data-testid="playlist-add-button"
            onClick={() => handleChangeAvailablePlaylistStatus(true)}
          >
            <AddIcon />
          </AddButton>
        )}
      </NavigationTitle>

      {items.map((item) => (
        <NavigationButton
          key={item.name}
          name={item.name}
          selected={selectedItem}
          onClick={onItemClick}
        >
          {item.description}
        </NavigationButton>
      ))}

      {showAvailableItems && (
        <ButtonBackPlaylist
          onClick={() => handleChangeAvailablePlaylistStatus(false)}
        >
          {localizedStrings.returnPlaylist}
        </ButtonBackPlaylist>
      )}
    </NavigationSection>
  );
};
