import React, { PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddAppletContainer,
  AppletPreviewButton,
  AppletPreviewContainer,
  StackContainerIcon,
  ButtonIcon,
  ContainerIcon,
  ButtonIconColoured,
} from '../../components';
import { sortByQuestSelect } from 'modules/profile/selectors';
import { updateSortByQuest } from 'modules/profile/actions';
import { QuestPortfolio } from 'models/QuestPortfolio';
import QuestAppletIcon from './QuestAppletIcon';
import AddAppletIcon from './AddAppletIcon';
import UserCardIcon from './UserCardIcon';
import FilterIcon from 'components/icons/FilterIcon';
import { Filters } from './helper';
import { QuestSectionFiltersGroup } from './QuestSectionFiltersGroup';

export const ALL_ID_ORDER = 900;

type QuestSectionProps = {
  quests: QuestPortfolio[];
  onAddAppletClick: (quest: QuestPortfolio) => void;
  filters?: Filters;
  handleChange: (el: any) => void;
};

const QuestSection = ({
  quests = [],
  onAddAppletClick,
  children,
  filters,
  handleChange,
}: PropsWithChildren<QuestSectionProps>) => {
  const dispatch = useDispatch();
  const sortByQuest = useSelector(sortByQuestSelect);
  const handleOpenAddApplet = () => onAddAppletClick(quests[0]);
  const handleChangeQuestState = () =>
    dispatch(updateSortByQuest(!sortByQuest));
  const [showFilters, setShowFilters] = useState(false);

  return (
    <ContainerIcon>
      {showFilters && (
        <QuestSectionFiltersGroup
          filters={filters}
          handleChange={handleChange}
        />
      )}
      <StackContainerIcon>
        <ButtonIconColoured onClick={() => setShowFilters(!showFilters)}>
          <FilterIcon />
        </ButtonIconColoured>
        <ButtonIcon onClick={handleChangeQuestState}>
          <QuestAppletIcon active={sortByQuest} />
        </ButtonIcon>
        <ButtonIcon onClick={handleChangeQuestState}>
          <UserCardIcon active={!sortByQuest} />
        </ButtonIcon>
      </StackContainerIcon>
      {children}
      <AppletPreviewContainer width="146px" margin="10px">
        <AppletPreviewButton
          onClick={handleOpenAddApplet}
          height="139px"
          width="146px">
          <AddAppletContainer>
            <AddAppletIcon />
          </AddAppletContainer>
        </AppletPreviewButton>
      </AppletPreviewContainer>
    </ContainerIcon>
  );
};

export default QuestSection;
