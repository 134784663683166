import {
  QuestFilterContainer,
  QuestFilterContainerColumn,
} from 'modules/profile/components';
import { QuestSectionFilter } from './QuestSectionFilter';
import { Filters } from './helper';

export type QuestSectionFiltersGroupProps = {
  handleChange: (el: any) => void;
  filters?: Filters;
};

export const QuestSectionFiltersGroup = ({
  filters,
  handleChange,
}: QuestSectionFiltersGroupProps) => {
  return (
    <QuestFilterContainer>
      <QuestFilterContainerColumn>
        {filters?.themes.length ? (
          <QuestSectionFilter
            filter={filters?.themes}
            label="Themes"
            onChange={handleChange}
          />
        ) : null}
        {filters?.audience.length ? (
          <QuestSectionFilter
            filter={filters?.audience}
            label="Audience"
            onChange={handleChange}
          />
        ) : null}
      </QuestFilterContainerColumn>
      <QuestFilterContainerColumn>
        {filters?.priority.length ? (
          <QuestSectionFilter
            filter={filters?.priority}
            label="Priority"
            onChange={handleChange}
          />
        ) : null}
        {filters?.type.length ? (
          <QuestSectionFilter
            filter={filters?.type}
            label="Type"
            onChange={handleChange}
          />
        ) : null}
      </QuestFilterContainerColumn>
    </QuestFilterContainer>
  );
};
