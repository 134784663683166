import { QuestPortfolio } from 'models/QuestPortfolio';

export type SelectedFilters = {
  themes: string;
  audience: string;
  priority: string;
  type: string;
};

export type Filters = {
  themes: string[];
  audience: string[];
  priority: string[];
  type: string[];
};

export const getFilters = (questPortfolios: QuestPortfolio[]) => {
  let themes: string[] = [];
  let audience: string[] = [];
  let priority: string[] = [];

  questPortfolios.forEach((quest) =>
    quest.applets.forEach((applet) => {
      if (applet.themes) themes = [...themes, ...applet.themes];
      if (applet.audience) audience = [...audience, ...applet.audience];
      if (applet.priority) priority.push(applet.priority);
    }),
  );

  return [themes, audience, priority];
};

export const getTypes = (value: string) => {
  let types = ['upload-file', 'generic-upload-file'];
  if (value === 'Images') {
    types.push('upload-image');
  }
  if (value === 'Videos') {
    types.push('upload-video');
  }
  if (value === 'Writing') {
    types = [
      'inline-text',
      'quiz',
      'decision-tree',
      'madlib',
      'random-selection',
      'rich-text-entry',
      'generic-rich-text',
    ];
  }
  return types;
};

export const getFilteredQuests = (
  questPortfolios: QuestPortfolio[],
  selectedFilters: SelectedFilters,
  types: string[],
) =>
  questPortfolios.map((quest) => ({
    ...quest,
    applets: quest.applets
      .filter((applet) =>
        selectedFilters.themes
          ? applet.themes?.includes(selectedFilters.themes)
          : true,
      )
      .filter((applet) =>
        selectedFilters.audience
          ? applet.audience?.includes(selectedFilters.audience)
          : true,
      )
      .filter((applet) =>
        selectedFilters.priority
          ? applet.priority === selectedFilters.priority
          : true,
      )
      .filter((applet) => (types.length ? types.includes(applet.type) : true)),
  }));
