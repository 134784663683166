import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAppletsFilledStateSelector,
  selectAvailableApplets,
} from 'modules/applets/selectors';
import QuestSection from './QuestSection';
import { QuestPortfolio } from 'models/QuestPortfolio';
import { ProfileSectionPanel } from '../../components';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreviewModal from './AppletPreviewModal';
import AddFileModal from './AddFileModal';
import AddAppletModal from './AddAppletModal';
import AddRichTextModal from './AddRichTextModal';
import { QuestSectionItems } from './QuestSectionItems';
import useActionStatus from 'hooks/useActionStatus';
import { getQuestApplets, getRestQuestApplets } from 'modules/applets/actions';
import { selectLoadingAdditionalFinished } from 'modules/loading-screen/quests/selectors';
import { CenterLoader } from 'components/loading-spinner/components';
import LoadingSpinner from 'components/loading-spinner';
import theme from 'utils/theme';
import { AppDispatch } from 'redux/typings';
import { APPLETS_GET_CURRENT_CONTENT } from 'modules/applets/typings';
import { getFilteredQuests, getFilters, getTypes } from './helper';

export type ModalState = {
  appletPreviewModalOpen?: boolean;
  addAppletModalOpen?: boolean;
  addFileModalOpen?: boolean;
  addRichTextModalOpen?: boolean;
  quest?: QuestPortfolio;
  applet?: QuestApplet;
};

const QuestsApplets = () => {
  const dispatch = useDispatch<AppDispatch>();
  const questPortfolios = useSelector(selectAvailableApplets);
  const [filteredQuests, setFilteredQuests] = useState<QuestPortfolio[]>();
  const [selectedFilters, setSelectedFilters] = useState({
    themes: '',
    audience: '',
    priority: '',
    type: '',
  });
  const [themes, audience, priority] = getFilters(questPortfolios);

  useEffect(() => {
    setFilteredQuests(questPortfolios);
  }, [questPortfolios]);

  const filters = {
    themes: [...new Set(themes)],
    audience: [...new Set(audience)],
    priority: [...new Set(priority)],
    type: ['Writing', 'Audio', 'Images', 'Videos'],
  };

  const [isPendingAchievements, wasFulfilledAchievements] =
    useActionStatus(getQuestApplets);
  const [isPendingRestAchievements] = useActionStatus(getRestQuestApplets);
  const { appletsActiveQuestFilled, appletsRestQuestFilled } = useSelector(
    selectAppletsFilledStateSelector,
  );
  const loadingFinished = useSelector(selectLoadingAdditionalFinished);

  const shouldShowLoader = useMemo(
    () =>
      !loadingFinished ||
      isPendingAchievements ||
      !wasFulfilledAchievements ||
      isPendingRestAchievements,
    [
      loadingFinished,
      isPendingAchievements,
      wasFulfilledAchievements,
      isPendingRestAchievements,
    ],
  );

  const [modalState, setModalState] = useState<ModalState>({});

  const openAppletPreviewModal = (applet: QuestApplet) => {
    setModalState({ appletPreviewModalOpen: true, applet });
  };

  const openAddAppletModal = (quest: QuestPortfolio) => {
    setModalState({ addAppletModalOpen: true, quest });
  };

  const openAddFileModal = (quest: QuestPortfolio) => {
    setModalState({ addFileModalOpen: true, quest });
  };

  const openAddRichTextModal = (quest: QuestPortfolio) => {
    setModalState({ addRichTextModalOpen: true, quest });
  };

  const closeModal = () => {
    setModalState({});
  };

  useEffect(() => {
    if (loadingFinished && !wasFulfilledAchievements) {
      dispatch(getQuestApplets());
    }
  }, [wasFulfilledAchievements, loadingFinished, dispatch]);

  useEffect(() => {
    if (
      !isPendingRestAchievements &&
      appletsActiveQuestFilled &&
      !appletsRestQuestFilled
    ) {
      dispatch(getRestQuestApplets(APPLETS_GET_CURRENT_CONTENT.NO));
    }
  }, [
    appletsActiveQuestFilled,
    appletsRestQuestFilled,
    isPendingRestAchievements,
    dispatch,
  ]);

  useEffect(() => {
    let types: string[] = [];
    if (selectedFilters.type) {
      types = getTypes(selectedFilters.type);
    }
    if (Object.values(selectedFilters).some((val) => val)) {
      setFilteredQuests(
        getFilteredQuests(questPortfolios, selectedFilters, types),
      );
    } else {
      setFilteredQuests(questPortfolios);
    }
  }, [selectedFilters, questPortfolios]);

  const handleFiltersChange = (el: any) => {
    setSelectedFilters((state) => ({
      ...state,
      [el.target.name.toLowerCase()]: el.target.value,
    }));
  };

  return (
    <ProfileSectionPanel>
      <AppletPreviewModal
        open={modalState.appletPreviewModalOpen ?? false}
        applet={modalState.applet!}
        closeModal={closeModal}
      />
      <AddAppletModal
        open={modalState.addAppletModalOpen ?? false}
        quest={modalState.quest!}
        closeModal={closeModal}
        onAddFileClick={openAddFileModal}
        onAddRichTextClick={openAddRichTextModal}
      />
      <AddFileModal
        open={modalState.addFileModalOpen ?? false}
        quest={modalState.quest!}
        closeModal={closeModal}
      />
      <AddRichTextModal
        open={modalState.addRichTextModalOpen ?? false}
        quest={modalState.quest!}
        closeModal={closeModal}
      />

      <QuestSection
        quests={questPortfolios}
        filters={filters}
        handleChange={handleFiltersChange}
        onAddAppletClick={openAddAppletModal}>
        <>
          <QuestSectionItems
            quests={filteredQuests}
            onAppletClick={openAppletPreviewModal}
            showTitles
          />
          {shouldShowLoader ? (
            <CenterLoader>
              <LoadingSpinner size={50} color={theme.colors.blue1[100]} />
            </CenterLoader>
          ) : null}
        </>
      </QuestSection>
    </ProfileSectionPanel>
  );
};

export default QuestsApplets;
